
import { Component, Vue, Watch } from 'vue-property-decorator'
import * as Sentry from '@sentry/vue'
import { useUserStore } from '@/stores/user'
import { useSettingsStore } from '@/stores/settings'

import SystemBar from '@/components/SystemBar.vue'
import SplashScreen from '@/views/splash-screen/index.vue'
import BottomNavigation from '@/components/BottomNavigation.vue'
import DesktopQrCode from '@/components/DesktopQrCode.vue'
import DesktopNavigation from '@/components/DesktopNavigation.vue'
import ServiceWorkerUpdatePopup from '@/pwa/components/ServiceWorkerUpdatePopup.vue'

import router from '@/router'
import NotificationService from '@/services/NotificationService'
import localization from '@/utils/localizationKommunicate'
import { getSubdomain } from '@/utils/subdomain-handler'

@Component({
  name: 'App',
  components: {
    SystemBar,
    SplashScreen,
    BottomNavigation,
    DesktopQrCode,
    DesktopNavigation,
    ServiceWorkerUpdatePopup,
    FallBackUrlBar: () => import('@/components/FallBackUrlBar.vue'),
    GiftMultiChoiceCard: () => import('@/components/RedeemGift/index.vue'),
    CustomHeader: () => import('@/components/HeaderCustom/index.vue')
  }
})
export default class extends Vue {
  private userStore = useUserStore()
  private settingsStore = useSettingsStore()

  deferredPrompt!: any

  splashFinished = false
  shouldShowSplash: null | boolean = null
  windowWidth = window.innerWidth

  @Watch('modelLoaded')
  async onUserLoaded(loaded: boolean) {
    if (loaded) {
      switch (this.user.program.name) {
        case 'Maaf avantages':
          document.querySelector('body')!.style.background = "url('./img/bg/bg-maaf.png') no-repeat center #eeeff56b"
          break;
        default:
          document.querySelector('body')!.style.background = "url('./img/bg/bg-desktop.png') no-repeat center #eeeff56b"
          break;
      }

      this.$vuetify.theme.themes.light.primary = this.user.program.primaryColor
      this.$vuetify.theme.themes.light.secondary = this.user.program.secondaryColor

      if (this.userStore.isLyfPayCustomer || this.userStore.isPcsCustomer) this.settingsStore.showPWAInstallCard = false

      // Get balance from PCS
      if (this.isPcsCustomer && this.user.program.balanceEnabled) await this.userStore.fetchBalance()

      Sentry.setUser({ email: this.user.email })
    }
  }

  get splashSettingsLoading() {
    return this.modelLoading || this.shouldShowSplash === null
  }

  get showApp() {
    return this.splashFinished && (this.modelLoaded || !this.isConnected)
  }

  get isConnected(): boolean {
    return (!!this.userStore.user && !!this.userStore.user.access_token) || !!this.userStore.token
  }

  get showCustomHeader(): boolean {
    return !this.$route.meta?.hideNavigation && (this.isBemoveCustomer || this.isAvantagesIEGCustomer)
  }

  get showNavigationTopBar(): boolean {
    return !this.$route.meta?.hideNavigation && !this.isBemoveCustomer && !this.isAvantagesIEGCustomer
  }

  get showNavigationBottomBar(): boolean {
    return !this.$route.meta?.hideNavigation
  }

  get user(): any {
    return this.userStore.model
  }

  get universalLinkLyf(): boolean {
    return !!this.$route?.query?.lyf
  }

  get modelLoading() {
    return this.userStore.modelLoading
  }

  get modelLoaded() {
    return !this.modelLoading && this.userStore.model.isPersisted
  }

  get userWantToInstall(): boolean {
    return this.settingsStore.PWAPrompt
  }

  get isIos(): boolean {
    return this.$device.isIOS
  }

  get isPcsCustomer(): boolean {
    return this.userStore.isPcsCustomer
  }

  get isBemoveCustomer(): boolean {
    return this.userStore.isBemoveCustomer
  }

  get isAvantagesIEGCustomer(): boolean {
    return this.userStore.isAvantagesIEGCustomer
  }

  get isFirefox(): boolean {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1
  }

  get kommunicate(): any {
    return (window as any).kommunicate
  }

  get isDesktop(): boolean {
    return this.$device.isDesktop
  }

  get isTablet(): boolean {
    return this.$device.isTablet
  }

  get displayDesktopNavigation() {
    return this.windowWidth >= 1400
  }

  get isStandalone(): boolean {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches

    if (document.referrer.startsWith('android-app://')) return false
    else if (('standalone' in window.navigator && (window.navigator as any).standalone) || isStandalone) return true
    return false
  }

  async created(): Promise<void> {
    this.$moment.locale('fr')

    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })

    // SplashScreen display control
    router.onReady(() => {
      this.shouldShowSplash = this.$route.query.splash === undefined ? true : this.$route.query.splash === 'true'
      if (this.userStore.optInRefused) this.shouldShowSplash = false
      if (!this.shouldShowSplash) this.splashFinished = true

      if (this.universalLinkLyf && this.isDesktop) NotificationService.openMobile()
    })

    // Disable right-click context menu
    document.addEventListener('contextmenu', function(e) {
      e.preventDefault()
    })

    // Prevent swipe to navigate gesture (Safari)
    const element = document.querySelector('#app')

    element?.addEventListener('touchstart', (e: any) => {
      // is not near edge of view, exit
      if (e.pageX > 10 && e.pageX < window.innerWidth - 10) return
      e.preventDefault()
    })

    // IOS DETECT IS NOT NAVIGATE WITH PWA
    if ((this.isIos && !this.isStandalone) && !this.userStore.isLyfPayCustomer && !this.userStore.isPcsCustomer) this.settingsStore.showPWAInstallCard = true
    if (this.isFirefox && !this.isStandalone) this.settingsStore.showPWAInstallCard = true

    // ANDROID EVENT HANDLING PWA IS NOT INSTALLED
    window.addEventListener('beforeinstallprompt', event => {
      event.preventDefault()
      if (!this.isStandalone && !this.userStore.isLyfPayCustomer && !this.userStore.isPcsCustomer) this.settingsStore.showPWAInstallCard = true

      this.deferredPrompt = event
    })

    window.addEventListener('appinstalled', event => {
      this.settingsStore.showPWAInstallCard = false
      setTimeout(async () => {
        await this.searchRelatedApp()
      }, 12000)
    })

    if (!this.userStore.isLyfPayCustomer || !this.userStore.isPcsCustomer) await this.searchRelatedApp()
  }

  async searchRelatedApp() {
    // DETECT IF USER HAS ALREADY INSTALLED PWA (ANDROID ONLY)
    if (!this.isStandalone && 'getInstalledRelatedApps' in navigator) {
      const relatedApps = await (navigator as any).getInstalledRelatedApps()
      relatedApps.forEach((app: any) => {
        this.settingsStore.relatedAppUrl = app.url
      })
    }
  }

  @Watch('userWantToInstall')
  async showPWAPrompt() {
    if (this.userWantToInstall) {
      this.deferredPrompt.prompt()

      const { outcome } = await this.deferredPrompt.userChoice
      if (outcome === 'accepted') {
        this.settingsStore.showPWAInstallCard = false
        this.deferredPrompt = null
      }
    }
    this.settingsStore.PWAPrompt = false
  }

  mounted(): void {
    this.setIconsInHeadHTML()

    if (!this.isPcsCustomer) {
      ((d, m) => {
        const kommunicateSettings = {
          appId: process.env.VUE_APP_KOMMUNICATE_ID,
          popupWidget: false,
          timeFormat24Hours: true,
          automaticChatOpenOnNavigation: false,
          restartConversationByUser: true,
          labels: localization.labels,
          onInit: () => {
            this.kommunicate.displayKommunicateWidget(false)

            this.$watch('modelLoaded', (loaded: boolean) => {
              if (loaded) {
                const user = {
                  userId: this.user.id,
                  email: this.user.email,
                  displayName: `${this.user.firstname} ${this.user.lastname}`,
                  userName: `${this.user.firstname} ${this.user.lastname}`,
                  metadata: {
                    "Program": this.user.program.name
                  }
                }
                this.kommunicate.updateChatContext(user)
                this.kommunicate.updateUser(user)
              }
            }, {immediate: true})
            const events = {
              onMessageReceived: () => {
                this.kommunicate.displayKommunicateWidget(true)
              },
              onChatWidgetClose: () => {
                this.kommunicate.displayKommunicateWidget(false)
                if (!this.isDesktop && !this.isTablet) this.$router.go(-1)
              },
              onMessageSent: () => {
                this.userStore.setNewSavConversation(String((window as any).KommunicateGlobal.CURRENT_GROUP_DATA.tabId))
              }
            }
            this.kommunicate.subscribeToEvents(events)
            this.kommunicate.customizeWidgetCss(
                '.km-chat-icon-sidebox, .mck-sidebox-launcher { width: 40px; height: 40px } .km-chat-icon-sidebox svg { width: 23px } .mck-back-btn-container { display: none !important } #launcher-agent-img-container, .mck-videocall-image { width: 40px !important; height: 40px !important; line-height: 45px !important } .km-iframe-sidebox-border-radius { border-radius: 0px !important } #launcher-agent-img-container img { height: 40px; width: 40px }'
            )
          }
        }
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true
        s.src = "https://widget.kommunicate.io/v2/kommunicate.app"
        const h = document.getElementsByTagName('head')[0];
        h.appendChild(s);
        (window as any).kommunicate = m;
        m._globals = kommunicateSettings
      })(document, (window as any).kommunicate || {})
    }
  }

  setIconsInHeadHTML(): void {
    const subdomain = getSubdomain()
    const iconsPath = subdomain === 'app' || subdomain === 'localhost' ? 'img/icons/' : `img/icons/${subdomain}/`

    // Specific code for Avantages IEG
    if (subdomain === 'avantagesieg') {
      this.$vuetify.theme.themes.light.primary = '#009FE6'
      this.$vuetify.theme.themes.light.secondary = '#E2077D'
    }

    document.title = `Walleo`
    document.querySelector('link[rel="icon"][type="image/png"][sizes="16x16"]')!.setAttribute('href', `/${iconsPath}favicon-16x16.png`);
    document.querySelector('link[rel="icon"][type="image/png"][sizes="32x32"]')!.setAttribute('href', `/${iconsPath}favicon-32x32.png`);
    document.querySelector('link[rel="icon"][type="image/png"][sizes="48x48"]')!.setAttribute('href', `/${iconsPath}favicon-48x48.png`);
    document.querySelector('link[rel="apple-touch-icon"]')!.setAttribute('href', `/${iconsPath}apple-touch-icon.png`);
    document.querySelector('link[rel="apple-touch-icon"][sizes="76x76"]')!.setAttribute('href', `/${iconsPath}apple-touch-icon-ipad.png`);
    document.querySelector('link[rel="apple-touch-icon"][sizes="120x120"]')!.setAttribute('href', `/${iconsPath}apple-touch-icon-retina.png`);
    document.querySelector('link[rel="apple-touch-icon"][sizes="152x152"]')!.setAttribute('href', `/${iconsPath}apple-touch-icon-ipad-retina.png`);
    document.querySelector('link[rel="mask-icon"][sizes="any"][color="black"]')!.setAttribute('href', `/${iconsPath}safari-pinned-tab.svg`);
    document.querySelector('link[rel="icon"][sizes="192x192"]')!.setAttribute('href', `/${iconsPath}icon-192x192.png`);
    document.querySelector('link[rel="icon"][sizes="128x128"]')!.setAttribute('href', `/${iconsPath}icon-128x128.png`);
    document.querySelector('link[rel="apple-touch-icon-precomposed"][sizes="52x52"]')!.setAttribute('href', `/${iconsPath}icon-52x52.png`);
    document.querySelector('link[rel="apple-touch-icon"][sizes="72x72"]')!.setAttribute('href', `/${iconsPath}icon-72x72.png`);
    document.querySelector('link[rel="icon"][type="image/svg+xml"]')!.setAttribute('href', `/${iconsPath}favicon.svg`);
    document.querySelector('link[rel="icon"][type="image/x-icon"]')?.setAttribute('href', `/${iconsPath}favicon.ico`);
  }
}
